<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <button
          class="button-default button-back uni-back"
          @click="$router.go(-1)"
        >
          <span></span>Назад
        </button>
        <div class="cabinet__head">
          <div class="title">Командный рейтинг по GPB-coins</div>
        </div>
        <div class="cabinet__body cabinet__body_type_rationg">
          <div class="cabinet__content">
            <div>
              <div class="uni-search">
                <input
                  type="text"
                  v-model="searchTeam"
                  placeholder="Поиск по названию команды"
                />
              </div>
              <div class="cabinet-table">
                <Loading v-if="loadingTeams" position="center" />
                <table>
                  <thead>
                    <tr>
                      <th>Название команды</th>
                      <th width="240px">
                        <div
                          class="uni-switcher uni-switcher_type_mini cabinet-table-switcher"
                          :class="{ active: !isActiveTotal }"
                        >
                          <button
                            class="uni-switcher__button"
                            @click="isActiveTotal = !isActiveTotal"
                          >
                            На планете
                          </button>
                          <button
                            class="uni-switcher__button"
                            @click="isActiveTotal = !isActiveTotal"
                          >
                            За игру
                          </button>
                          <Tooltip
                            class="tooltip_style_dark"
                            :msg="`
                              <table>
                                <tr><td>За игру</td><td>Количество GPB-coins, полученных за <br/>все время</td></tr>
                                <tr><td>На планете</td><td>Отображаются GPB-coins, полученные на <br/>текущей планете. Рейтинг обновляется <br/>еженедельно, данные при переходе на <br/>новую планету будут доступны со второй <br/>недели</td></tr>
                              </table>
                            `"
                          />
                        </div>
                      </th>
                      <th width="100px">Место</th>
                    </tr>
                  </thead>
                  <tbody>
                    <router-link
                      v-for="(item, index) in filteredListTeam"
                      :key="index"
                      tag="tr"
                      :class="{ myself: item.teamName === getProfile.teamName }"
                      :to="{ name: 'team', params: { teamID: item.teamId } }"
                    >
                      <td>{{ item.teamName }}</td>
                      <td>
                        <div class="uni-coins uni-coins_type_table">
                          <img
                            src="@/assets/img/pic2/coin.svg"
                            width="15"
                            height="15"
                            alt=""
                          />{{
                            isActiveTotal
                              ? item.calculatedCoins
                              : weekCoinsFilter(
                                  item.calculatedCoinsCurrentIsland
                                )
                          }}
                        </div>
                      </td>
                      <td>
                        {{ index + 1 }}
                      </td>
                    </router-link>
                  </tbody>
                </table>
                <div class="cabinet-table__more">
                  <button
                    v-if="
                      !searchTeam && limitTeam && limitTeam < getTeams.length
                    "
                    @click="limitTeam = null"
                  >
                    Смотреть все
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="cabinet__sidebar">
            <Dashboard view="team" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import Dashboard from "@/components/Dashboard.vue";
import { mapGetters, mapActions } from "vuex";
import Tooltip from "@/components/Tooltip.vue";

export default {
  props: ["type"],
  components: {
    CommonLayout,
    Dashboard,
    Tooltip,
  },
  data() {
    return {
      limitTeam: 7,
      limitSearch: 7,
      searchTeam: "",
      isActiveTotal: false,
    };
  },
  computed: {
    ...mapGetters(["getProfile", "getTeamsStatus", "getTeams"]),
    loadingTeams() {
      return this.getTeamsStatus === "loading";
    },
    filteredListTeam() {
      const sorting = (a, b) => {
        if (this.isActiveTotal) {
          return b.calculatedCoins - a.calculatedCoins;
        } else {
          return (
            b.calculatedCoinsCurrentIsland - a.calculatedCoinsCurrentIsland
          );
        }
      };

      if (this.searchTeam) {
        return this.getTeams
          .filter((item) => {
            return item.teamName
              .toLowerCase()
              .includes(this.searchTeam.toLowerCase());
          })
          .sort(sorting)
          .slice(0, this.limitSearch);
      }

      return this.limitTeam
        ? this.getTeams
            .map((e) => e)
            .sort(sorting)
            .slice(0, this.limitTeam)
        : this.getTeams.map((e) => e).sort(sorting);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(["TEAMS_FETCH"]),
    fetchData() {
      if (this.getTeamsStatus !== "success") {
        this.TEAMS_FETCH();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-table {
  td,
  th {
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: center;
    }
  }
}

.myself {
  background-image: url("~@/assets/img/icon/star-white.svg");
  background-repeat: no-repeat;
  background-position: 7px calc(50% + 1px);
}
</style>
